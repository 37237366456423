import React, { useState } from 'react';
import { SampleSide, ScrollableMessageList, BottomShadow } from './MessageOverlay.styles';
import { Button } from '../../components/Buttons';
import { MessageQuote } from '../../components/MessageQuote';

import send from '../../assets/icons/send.svg';

const MessagePanel = ({ close, isMarker = false, form, showForm, setShowForm, messageInfo, reveal, replyMode, setReplyMode, specificReplyFromList, setSpecificReplyFromList, ...props }) => {
  const [forceForm, setForceForm] = useState(false)

  useState(() => {
    if (isMarker && showForm) setForceForm(true)
  }, [isMarker, showForm])

  if (isMarker && !messageInfo) {
    return null
  } else return (
    <SampleSide formOpen={!isMarker || showForm} isMarker={isMarker} listMode={Array.isArray(messageInfo)} {...props}>
      { (!isMarker || showForm) && !replyMode ? <h5 className={reveal ? "yellow appear active show-order-12" : "yellow appear show-order-12"}>Live preview of your message!</h5> : null}
      {
        isMarker || replyMode ? // Show the preview message 
        Array.isArray(messageInfo) && !forceForm ?
          <ScrollableMessageList>
            {messageInfo.map((message, i) => 
              <MessageQuote
                allowMessage={message.allowMessage}
                className={reveal ? `show-up active show-order-${10 + i}` : `show-up show-order-${10 + i}`}
                key={message._id}
                image={message.image}
                messanger={message.name}
                message={message.message}
                location={message.city}
                trend={message.trend}
                linkedIn={message.linkedin}
                openReply={() => {
                  setShowForm(true)
                  setForceForm(true)
                  setReplyMode(true)
                  setSpecificReplyFromList(message)
                }}
              />
            )}
          </ScrollableMessageList>
        :
          <MessageQuote
            allowMessage={(showForm || forceForm) && !replyMode ? form.allowMessage : specificReplyFromList ? specificReplyFromList.allowMessage : messageInfo.allowMessage}
            close={close}
            className={reveal ? "show-up active show-order-10" : "show-up show-order-10"}
            image={(showForm || forceForm) && !replyMode ? form.imagePreview : specificReplyFromList ? specificReplyFromList.image : messageInfo ? messageInfo.image : null}
            messanger={(showForm || forceForm) && !replyMode ? form.name : specificReplyFromList ? specificReplyFromList.name : messageInfo.name}
            message={(showForm || forceForm) && !replyMode ? form.message : specificReplyFromList ? specificReplyFromList.message : messageInfo.message}
            location={(showForm || forceForm) && !replyMode ? form.city : specificReplyFromList ? specificReplyFromList.city : messageInfo.city}
            trend={(showForm || forceForm) && !replyMode ? form.trend: specificReplyFromList ? specificReplyFromList.trend : messageInfo.trend}
            linkedIn={(showForm || forceForm) && !replyMode ? form.linkedin : specificReplyFromList ? specificReplyFromList.linkedin : messageInfo.linkedin}
            formMode={(showForm || forceForm) && !replyMode}
            openReply={(showForm || forceForm) && !replyMode ? null : () => {
              setShowForm(true)
              setForceForm(true)
              setReplyMode(true)
            }}
          />
        : // Show the list of messages TODO
        <MessageQuote
          allowMessage={form.allowMessage}
          className={reveal ? "show-up active show-order-12" : "show-up show-order-12"}
          image={form.imagePreview}
          messanger={form.name}
          message={form.message}
          location={form.city}
          trend={form.trend}
          linkedIn={form.linkedin}
          formMode={true}
         />
      }
      {isMarker && !showForm && (
        <>
          <Button className={reveal ? "appear active show-order-10" : "appear show-order-10"} style={{ position: 'absolute', bottom: 0, alignSelf: 'center', width: 343, zIndex: 2 }} size="medium" onClick={() => {
            setShowForm(true)
            setForceForm(true)
          }}>
            <img alt="arrow" src={send} style={{ marginLeft: 0, marginRight: 16 }} />
            <p className="button mainBlue">Add Message To Globe</p>
          </Button>
          {/* {Array.isArray(messageInfo) && <BottomShadow />} */}
        </>
      )}
    </SampleSide>
  );
};

export default MessagePanel;
