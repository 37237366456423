import React from "react";
import styled from "styled-components/macro";
import { Colors } from "../utils/Colors";

import click from "../assets/icons/click_V2.svg";
import { Corner } from "./common";

const CardContainer = styled.div`
  background: ${Colors.darkBlue};
  box-shadow: 0px 10px 40px ${Colors.darkBlue};
  width: 400px;
  height: 98px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;

  & .meta-1 {
    margin-bottom: 2px;
    line-height: 20px;
  }

  & .small {
    line-height: 20px;
  }
`;

const FrontCard = ({ headline, subline, ...props }) => {
  return (
    <CardContainer {...props}>
      <IconContainer>
        <img alt="click" src={click} />
      </IconContainer>
      <TextContainer>
        <p className="meta-1 yellow">{headline}</p>
        <p className="small">{subline}</p>
      </TextContainer>
      {/* Corners */}
      <Corner style={{ position: "absolute", top: 0, left: 0 }} />
      <Corner
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          transform: "rotate(-90deg)",
        }}
      />
      <Corner
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          transform: "rotate(-180deg)",
        }}
      />
      <Corner
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          transform: "rotate(90deg)",
        }}
      />
    </CardContainer>
  );
};

export default FrontCard;
