import React from 'react';
import styled from 'styled-components/macro';
import returnIcon from '../assets/icons/return.svg';

const BtnContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 3%;
  display: flex;
  z-index: 10;

    
  @media (max-height: 800px) {
    .return-button {
      width: 45px;
      height: 45px;
    }
  }
`;

const ReturnButton = ({ close, ...props }) => {
  return (
      <BtnContainer {...props}>
        <img alt="close" src={returnIcon} style={{ cursor: 'pointer' }} onClick={close} className="return-button" />
        <p className="button" style={{ marginLeft: 20, alignSelf: 'center'}}>Return to globe</p>
      </BtnContainer>
  );
};

export default ReturnButton;
