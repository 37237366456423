import React, { useContext, useEffect, useState } from 'react';
import { AudioContext } from '../contexts/AudioContext';
import { StageContext } from '../contexts/StageContext';
import { userPlayAudio } from '../utils/helpers';

import soundon from '../assets/icons/squared.svg';
import soundoff from '../assets/icons/sound_off.svg';

const SoundButton = () => {
  const [audio, setAudio] = useContext(AudioContext);
  const [stage] = useContext(StageContext);
  const [sound, setSound] = useState(audio)

  return ( 
    <img alt="volume" src={sound ? soundon : soundoff} style={{ position: 'absolute', right: '3%', top: '5%', cursor: 'pointer' }} onClick={() => {
      if (!audio && stage === 'globe') userPlayAudio()
      setAudio(!audio)
      setSound(!sound)
    }} />
   );
}
 
export default SoundButton;