import React, { useRef } from "react";
import styled from "styled-components/macro";
import { Colors } from "../utils/Colors";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { FlexContainer } from "./common";

import upload from "../assets/icons/upload.svg";
import checkmark from "../assets/icons/checkmark.svg";
import checkgreen from "../assets/icons/checkgreen.svg";
import error from "../assets/icons/error.svg";

export const FormInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  & label {
    color: ${({ validate }) =>
      validate === false ? Colors.error : Colors.gray};
  }

  & .file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  & .file-input .upload {
    background: #f5f6ff;
    outline: none;
    border: none;
    border-radius: 16px;
    width: 100%;
    min-height: 88px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: ${Colors.mainBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .validated {
    position: absolute;
    right: 15px;
    top: 33px;
    height: 32px;
  }
`;

export const Input = ({
  label,
  placeholder,
  type,
  value,
  setValue,
  maxLength,
  validate,
  validateError,
}) => {
  return (
    <FormInputContainer validate={validate}>
      <label>
        {validate === false && validateError ? validateError : label}
      </label>
      <input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={maxLength || null}
      />
      {value.length > 3 &&
      (validate === null || validate === undefined || validate === true) ? (
        <img alt="check" src={checkgreen} className="validated" />
      ) : validate === null ||
        validate === undefined ||
        validate === true ? null : (
        <img alt="error" src={error} className="validated" />
      )}
    </FormInputContainer>
  );
};

export const ImageInput = ({ label, setValue, value }) => {
  const ref = useRef(null);

  return (
    <FormInputContainer>
      <label>{label}</label>
      <div className="file-input">
        <input
          type="file"
          accept="image/png,image/jpeg"
          id="file"
          className="file"
          ref={ref}
          onChange={(e) =>
            e.target.files && e.target.files[0]
              ? setValue(
                  URL.createObjectURL(e.target.files[0]),
                  e.target.files[0]
                )
              : null
          }
        />
        <div className="upload" onClick={() => ref.current.click()}>
          {value ? (
            <img alt="checkmark" src={checkmark} style={{ marginRight: 8 }} />
          ) : (
            <img alt="upload" src={upload} style={{ marginRight: 8 }} />
          )}
          {value ? "Successfully added!" : "Upload image"}
        </div>
      </div>
    </FormInputContainer>
  );
};

export const TextArea = ({ label, placeholder, type, value, setValue }) => {
  return (
    <FormInputContainer>
      <label>{label}</label>
      <textarea
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        minLength="15"
        maxLength="160"
      />
    </FormInputContainer>
  );
};

export const Checkbox = ({ label, value, setValue, link }) => {
  return (
    <FlexContainer
      size="none"
      justify="flex-start"
      align="flex-start"
      onClick={() => (link ? window.open(link, "__blank") : null)}
      style={{ cursor: link ? "pointer" : "default" }}
    >
      <input
        type="checkbox"
        value={value}
        onChange={(e) => setValue(e.target.checked)}
      />
      <p className="labeled mainBlue" style={{ marginLeft: 8 }}>
        {label}
      </p>
    </FlexContainer>
  );
};

export const LocationInput = ({ label, placeholder, setValue }) => {
  return (
    <FormInputContainer>
      <label>{label}</label>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyDRIO_UnIQdtT_ySxWF5YlOA0dKdq8gL8Y"
        apiOptions={{ language: "en" }}
        selectProps={{
          className: "globe",
          classNamePrefix: "globe",
          onChange: (e) => {
            console.log(e);
            geocodeByPlaceId(e.value.place_id).then((results) => {
              console.log(results);
              getLatLng(results[0]).then((coord) => {
                console.log(coord);
                setValue(e, coord);
              });
            });
          },
          noOptionsMessage: () => "Type to see results",
          placeholder: placeholder,
        }}
      />
    </FormInputContainer>
  );
};
