import styled, { css } from "styled-components/macro";
import { Colors } from "../../utils/Colors";

import { animated } from "react-spring";

export const MessageOverlayContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: ${Colors.darkBlue}; */
  background: transparent;
  display: flex;
  z-index: 5;
  pointer-events: none;

  transition: transform 0.8s 0.3s;

  & * {
    pointer-events: auto;
  }
`;

export const SampleSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ isMarker }) =>
    isMarker ? "transparent" : Colors.fadedDarkBlue};
  align-items: center;
  padding: 50px 100px;
  width: 60vw;
  height: 100%;
  position: relative;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  transform: translateX(-100%);

  transition: transform 0.5s ease-in-out;

  &.visible {
    transform: translateX(0%);
    transition: transform 1.2s 0.4s ease-in-out;
  }

  & > * {
    margin: 24px 0px;
  }

  & h3 {
    max-width: 405px;
  }

  & h5 {
    font-family: "Inter", sans-serif;
    margin-top: 0;
    width: 100%;
  }

  & .message-image {
    max-width: 273px;

    & img {
      width: 100%;
    }
  }

  & .message {
    margin-left: 40px;
    height: 100%;

    & > * {
      margin: 8px 0px;
    }
  }

  ${({ listMode }) =>
    listMode &&
    css`
      padding-bottom: 0;
    `}

  @media (max-width: 1680px) {
    width: ${({ formOpen }) => (formOpen ? "55vw" : "75vw")};
    padding: 50px 50px;
  }
`;

export const FormSide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40vw;
  height: 100%;
  background: ${Colors.white};
  border-radius: 24px 0px 0px 24px;
  padding: 50px 100px;
  transform: translateX(100%);

  transition: transform 1.2s 0.6s ease-in-out;

  &.visible {
    transform: translateX(0%);
  }

  & > p {
    color: ${Colors.gray};
    margin-top: 8px;
  }

  & .globe__control {
    background: #f5f6ff !important;
    outline: none !important;
    border: none !important;
    border-radius: 100px !important;
    width: 100% !important;
    min-height: 52px !important;
    /* padding: 18px 20px !important; */
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.02em !important;
    color: black !important;
    padding: 0px 20px;
  }

  & .globe__value-container {
    padding: 0 !important;
  }

  & .globe__option,
  & .globe__option *,
  & .globe__control * {
    color: ${Colors.mainBlue};
  }

  & .globe__placeholder {
    color: #6b6b6d !important;
  }

  @media (max-width: 1680px) {
    width: ${({ formOpen }) => (formOpen ? "45vw" : "35vw")};
    padding: 50px 80px;
  }

  @media (max-width: 1400px) {
    padding: 50px 50px;
  }

  @media (max-height: 850px) {
    padding: 30px 50px;
  }
`;

export const FormSideClickable = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 47vw;
  height: 100%;

  @media (max-width: 1880px) {
    width: 45vw;
  }

  @media (max-width: 1740px) {
    width: 43vw;
  }

  @media (max-width: 1678px) {
    width: 38vw;
  }

  @media (max-width: 1540px) {
    width: 35vw;
  }

  @media (max-width: 1430px) {
    width: 30vw;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0px;

  & > * {
    margin-bottom: 24px;
  }

  & .globe__value-container {
    & div:nth-child(2) {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  & span {
    font-family: "Inter", sans-serif;
    color: ${Colors.yellow};
  }
`;

export const SuccessMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7vh;

  & h5 {
    margin: 25px 0px;
  }
`;

export const ScrollableMessageList = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  margin-bottom: 0;

  & > div:last-child {
    margin-bottom: 120px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

export const BottomShadow = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 14.5%;
  width: 819px;
  height: 250px;
  background: linear-gradient(
    180deg,
    rgba(8, 9, 40, 0) 27.27%,
    rgba(8, 9, 40, 0) 18.96%,
    #141418 95.95%
  );
  margin-bottom: 0;
`;

export const FormContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media (max-height: 850px) {
    overflow: scroll;
  }
`;
