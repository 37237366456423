export const Colors = {
  mainBlue: "#141418",
  yellow: "#FFD100",
  darkBlue: "#141418",
  gray: "#4B4A5E",
  white: "#FFFFFF",
  lightblue: "#F5F6FF",
  error: "#DB4201",
  fadedDarkBlue: "rgb(20 20 24 / 90%)",
};
