import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import { Button } from "./Buttons";
import { FlexContainer } from "./common";
import RevealSplitText from "./RevealSplitText";

import pin from "../assets/icons/user_outline.svg";
import send from "../assets/icons/send_transp.svg";
import search from "../assets/icons/search.svg";
import { Colors } from "../utils/Colors";
import ParticleEffectButton from "react-particle-effect-button";

const TitleWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s ease-out;
  opacity: 0;

  & .hide-minimized {
    transition: opacity 0.6s;
  }

  ${({ revealed }) =>
    revealed &&
    css`
      opacity: ${({ active }) => (active ? 1 : 0)};
    `}

  ${({ minimized }) =>
    minimized &&
    css`
      pointer-events: none;

      & .hide-minimized {
        transition-delay: 0s !important;
        opacity: 0;
        pointer-events: none;
      }
    `}

  @media (max-width: 1400px) {
    left: 48px;

    ${({ fullyMinimized }) =>
      fullyMinimized &&
      css`
        display: none;
      `}
  }

  @media (max-height: 700px) {
    & .visitor-count {
      margin-bottom: 20px;
    }

    & .buttons {
      margin-top: 30px;
    }
  }
`;

const MessageWrapper = styled.div`
  position: absolute;
  right: 100px;
  bottom: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 455px;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateY(50%);
  transition: opacity 0.5s ease-out, transform 0.7s 0.5s ease-in-out;
  opacity: 0;

  & * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  & span {
    font-family: "Inter", sans-serif;
    margin: 0px 10px;
  }

  & .visitor-count {
    margin-bottom: 30px;
  }

  & .first-button {
    margin-bottom: 20px;
  }

  & .hide-minimized {
    transition: opacity 0.6s;
  }

  ${({ revealed }) =>
    revealed &&
    css`
      opacity: ${({ active }) => (active ? 1 : 0)};
    `}

  ${({ minimized }) =>
    minimized &&
    css`
      pointer-events: none;

      & .hide-minimized {
        transition-delay: 0s !important;
        opacity: 0;
        pointer-events: none;
      }
    `}

  @media (max-width: 1400px) {
    left: 48px;

    ${({ fullyMinimized }) =>
      fullyMinimized &&
      css`
        display: none;
      `}
  }

  @media (max-height: 700px) {
    & .visitor-count {
      margin-bottom: 20px;
    }

    & .buttons {
      margin-top: 30px;
    }
  }
`;

const SideIntro = ({ number, show, active, minimized, explore, revealed }) => {
  const [fullyMinimized, setFullyMinimized] = useState(false);

  useEffect(() => {
    if (minimized) {
      setTimeout(() => setFullyMinimized(true), 700);
    }
  }, [minimized]);

  return (
    <>
      <TitleWrapper
        active={active}
        minimized={minimized}
        revealed={revealed}
        fullyMinimized={fullyMinimized}
      >
        <RevealSplitText
          className="headline hide-minimized"
          wordDelay={1}
          wait={!revealed}
        >
          Explore baking around
        </RevealSplitText>
        <RevealSplitText
          className="headline hide-minimized"
          wordDelay={1.2}
          wait={!revealed}
        >
          the world with us.
        </RevealSplitText>
        {/* <RevealSplitText
          className="headline hide-minimized"
          wordDelay={1.4}
          wait={!revealed}
        >
          baking, explore!
        </RevealSplitText> */}
      </TitleWrapper>
      <MessageWrapper
        active={active}
        minimized={minimized}
        revealed={revealed}
        fullyMinimized={fullyMinimized}
      >
        {/* <FlexContainer
        size="none"
        justify="flex-start"
        className={
          revealed
            ? "visitor-count hide-minimized show-up show-order-19 active"
            : "visitor-count hide-minimized show-up"
        }
      >
        <img alt="pin" src={pin} style={{ marginRight: 12 }} />
        <p className="meta-1">iba global community: {number} visitors</p>
      </FlexContainer> */}

        {/* <h3 className="headline hide-minimized" >Welcome to the iba world of baking, explore!</h3> */}
        <ParticleEffectButton
          hidden={minimized}
          color={Colors.yellow}
          direction="top"
        >
          <Button
            size="large"
            onClick={explore}
            className={
              revealed
                ? "buttons first-button hide-minimized show-up show-order-20 active"
                : "buttons first-button hide-minimized show-up"
            }
          >
            <img alt="arrow" src={search} />
            <p className="button mainBlue">Start Exploring</p>
          </Button>
        </ParticleEffectButton>
        <ParticleEffectButton
          hidden={minimized}
          color={Colors.white}
          direction="bottom"
        >
          <Button
            size="large"
            transparent
            onClick={show}
            className={
              revealed
                ? "buttons hide-minimized show-up show-order-21 active"
                : "buttons hide-minimized show-up"
            }
          >
            <img alt="arrow" src={send} />
            <p className="button">Add New Message</p>
          </Button>
        </ParticleEffectButton>
      </MessageWrapper>
    </>
  );
};

export default SideIntro;
