import React, { useState, useEffect, useContext } from "react";
import { useSpring } from "react-spring";
import { useMove } from "react-use-gesture";
import ReactGlobe from "react-globe";

import { AudioContext } from "../../contexts/AudioContext";
import { Logo } from "../../components/common";
import SideIntro from "../../components/SideIntro";
import BakeryPreview from "../../components/BakeryPreview";
import { HoverLabel, GlobeCover, MiddleMessage } from "./Globe.styles";
import BakeryProfile from "../BakeryProfile/BakeryProfile";

import click from "../../assets/icons/click_V2.svg";
import send from "../../assets/icons/send_transp.svg";
import search from "../../assets/icons/search.svg";
import globeTexture from "../../assets/images/globe/iba_nightglobe_update.jpg";
import BakeryMoreInfo from "../BakeryMoreInfo/BakeryMoreInfo";
import { getAllMarkers } from "../../services/markerService";
import MessageOverlay from "../MessageOverlay/MessageOverlay";
import markerRenderer from "../../utils/markerRender";
import { getMarkerType, playSound } from "../../utils/helpers";
import SoundButton from "../../components/SoundButton";
import { Button } from "../../components/Buttons";
import ParticleEffectButton from "react-particle-effect-button";
import { Colors } from "../../utils/Colors";

let bakeryPreviewTimeout = null;

const options = {
  enableCameraZoom: true,
  enableCameraAutoRotate: true,
  enableCameraRotate: true,
  cameraAutoRotateSpeed: 0.01,
  cameraRotateSpeed: 0.2,
  cameraDistanceRadiusScale: 3.1,
  cameraMaxDistanceRadiusScale: 3.1,
  cameraZoomSpeed: 0.1,
  enableMarkerGlow: true,
  enableMarkerTooltip: false,
  focusAnimationDuration: 2000,
  focusDistanceRadiusScale: 2,
  markerRenderer: markerRenderer,
  markerRadiusScaleRange: [0.007, 0.01],
  markerType: "dot",
  markerTooltipRenderer: (marker) => {
    if (getMarkerType(marker.id) === "CLUST") {
      return `Messages from ${marker.city}`;
    }
    if (getMarkerType(marker.id) === "MSG") {
      return `${marker.messageInfo.name} - ${marker.city}`;
    }
    if (getMarkerType(marker.id) === "BAKE") {
      return null;
    }
  },
  markerEnterAnimationDuration: 1500,
  markerEnterEasingFunction: ["Bounce", "InOut"],
  markerExitAnimationDuration: 1500,
  markerExitEasingFunction: ["Cubic", "Out"],
  globeGlowCoefficient: 0,
  globeGlowColor: "#1A1A34",
  pointLightIntensity: 0.5,
};

const initial = [48.15075331217369, 11.540990169524433];

function Globe() {
  // Animate
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }));
  // Globe States
  const [loaded, setLoaded] = useState(false);
  const [hasGlobeCloudsTextureLoaded, setHasGlobeCloudsTextureLoaded] =
    useState(false);
  const [hasGlobeTextureLoaded, setHasGlobeTextureLoaded] = useState(false);
  const [exploreMode, setExploreMode] = useState(false);
  const [focus, setFocus] = useState(null);
  const [globe, setGlobe] = useState(null);
  const [globeFocused, setGlobeFocused] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [hoveredType, setHoveredType] = useState(null);
  // Bakery State
  const [showBakery, setShowBakery] = useState(false);
  const [bakeryInfo, setBakeryInfo] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [previewed, setPreviewed] = useState(null);
  // Messages States
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  // General States
  const [count, setCount] = useState(0);
  const [bakeriesOnly, setBakeriesOnly] = useState(false);
  const [audio] = useContext(AudioContext);

  const bind = useMove(({ xy }) => {
    set({ xy });
  });

  const fetchMarkers = async () => {
    let { data } = await getAllMarkers();
    let bakeryMarkers = data.markers;

    console.log(data);

    if (window.location.hash && window.location.hash === "#globe-bakeries") {
      setBakeriesOnly(true);
      bakeryMarkers = data.markers.filter((pin) => pin.id.includes("bakery"));
      playSound("main_audio", true, null, 0.1);
    }

    setMarkers(bakeryMarkers);
    setCount(data.count);
  };

  useEffect(() => {
    fetchMarkers();
  }, []);

  useEffect(() => {
    if (exploreMode || showBakery || globeFocused)
      playSound("globe_zoom", audio);
  }, [globeFocused, showBakery, exploreMode]);

  useEffect(() => {
    if (hasGlobeTextureLoaded && hasGlobeCloudsTextureLoaded) {
      const event = new CustomEvent("globe");
      document.dispatchEvent(event);
      console.log("sent event");
      setLoaded(true);
    }
  }, [hasGlobeCloudsTextureLoaded, hasGlobeTextureLoaded]);

  const closeOverlays = () => {
    setFocus(null);
    setGlobeFocused(false);
    globe.updateFocus(null);
    setSelectedMessage({});
  };

  const clearBakeryMode = () => {
    window.location.hash = "";
    setBakeriesOnly(false);
    fetchMarkers();
  };

  const getCorrectLabelMessage = () => {
    if (selectedMessage && Array.isArray(selectedMessage)) {
      return `${selectedMessage.length} Messages from ${
        selectedMessage && selectedMessage[0].city
      }`;
    } else if (selectedMessage) {
      return `Message from ${selectedMessage.name}`;
    }
  };

  const OnMarkerClick = (marker) => {
    if (getMarkerType(marker.id) === "MSG") {
      if (marker.messageInfo === null) return;
      setSelectedMessage(marker.messageInfo);
      setGlobeFocused(true);
    }

    if (getMarkerType(marker.id) === "CLUST") {
      if (marker.clusterInfo.messages === null) return;
      setSelectedMessage(marker.clusterInfo.messages);
      setGlobeFocused(true);
    }

    if (getMarkerType(marker.id) === "BAKE") {
      if (selectedMessage && globeFocused) {
        var event = new CustomEvent("bakery-selected");

        window.dispatchEvent(event);

        setTimeout(() => {
          setShowBakery(true);
          clearTimeout(bakeryPreviewTimeout);
          setBakeryInfo(marker.bakeryInfo);
        }, 2100);
      } else {
        setShowBakery(true);
        clearTimeout(bakeryPreviewTimeout);
        setBakeryInfo(marker.bakeryInfo);
      }
    }
  };

  const onMouseOverMarker = (marker) => {
    if (showBakery || showMessageForm || globeFocused) return;

    setHoveredType(getMarkerType(marker.id));
    clearTimeout(bakeryPreviewTimeout);

    if (getMarkerType(marker.id) === "BAKE") {
      setBakeryInfo(marker.bakeryInfo);
    }

    if (getMarkerType(marker.id) === "MSG") {
      setSelectedMessage(marker.messageInfo);
    }

    if (getMarkerType(marker.id) === "CLUST") {
      setSelectedMessage(marker.clusterInfo.messages);
    }

    setPreviewed(true);
  };

  const onMouseOutMarker = (marker) => {
    setPreviewed(false);

    if (getMarkerType(marker.id) === "BAKE") {
      bakeryPreviewTimeout = setTimeout(() => {
        if (!showBakery) setBakeryInfo({});
        setHoveredType(null);
      }, 570);
    }

    if (getMarkerType(marker.id) === "MSG") {
      bakeryPreviewTimeout = setTimeout(() => {
        if (!globeFocused) setSelectedMessage(null);
        setHoveredType(null);
      }, 570);
    }

    if (getMarkerType(marker.id) === "CLUST") {
      bakeryPreviewTimeout = setTimeout(() => {
        if (!globeFocused) setSelectedMessage(null);
        setHoveredType(null);
      }, 570);
    }
  };

  const globeContainer = {
    width: "100vw",
    cursor: "grab",
    transition: "transform 2s ease",
    transform:
      "translateY(-10vh) " +
      (globeFocused
        ? `translateX(${window.innerWidth > 1465 ? 35 : 0}%) scale(1)`
        : exploreMode
        ? "translateX(-3vw) scale(1)"
        : "translateX(-3vw) scale(0.8)"),
  };

  return (
    <div>
      <div
        {...bind()}
        style={globeContainer}
        onMouseDown={() => (exploreMode ? null : setExploreMode(true))}
      >
        <ReactGlobe
          height="120vh"
          width="106vw"
          focus={focus}
          globeTexture={globeTexture}
          globeBackgroundTexture={null}
          options={options}
          markers={markers}
          onGlobeTextureLoaded={() => setHasGlobeTextureLoaded(true)}
          onGlobeCloudsTextureLoaded={() =>
            setHasGlobeCloudsTextureLoaded(true)
          }
          onClickMarker={OnMarkerClick}
          onMouseOverMarker={onMouseOverMarker}
          onMouseOutMarker={onMouseOutMarker}
          onDefocus={() => setGlobeFocused(false)}
          onGetGlobe={setGlobe}
          initialCoordinates={initial}
        />
      </div>
      <GlobeCover loaded={loaded} />
      <HoverLabel
        type={hoveredType}
        style={{
          transform: xy.interpolate((x, y) => `translate3d(${x}px, ${y}px, 0)`),
          opacity: previewed ? 1 : 0,
          transition: previewed
            ? "opacity .4s ease-out"
            : "opacity .4s .2s ease-out",
        }}
      >
        {hoveredType && hoveredType === "BAKE" ? (
          <>
            <h5 className="yellow">{bakeryInfo.name || "Backer Baier"}</h5>
            <p className="meta-3">
              {bakeryInfo.vitals &&
                (bakeryInfo.vitals[0].location || "Stuttgart, Germany")}
            </p>
          </>
        ) : (
          <>
            <p>{getCorrectLabelMessage()}</p>
          </>
        )}
      </HoverLabel>
      <SideIntro
        revealed={loaded}
        number={count}
        show={() => setShowMessageForm(true)}
        active={!showMessageForm && !globeFocused}
        minimized={exploreMode}
        explore={() => setExploreMode(true)}
      />
      <BakeryPreview
        active={previewed && bakeryInfo && hoveredType === "BAKE"}
        bakery={bakeryInfo}
      />
      <SoundButton />
      <MiddleMessage
        className={exploreMode && !globeFocused ? "show b1" : "b1"}
      >
        <span>
          <img alt="pin" src={click} style={{ marginRight: 12 }} />
        </span>
        Drag & Scroll to explore
      </MiddleMessage>
      <Button
        size="large"
        onClick={clearBakeryMode}
        className={
          exploreMode && !globeFocused && bakeriesOnly
            ? "show-up show-order-12 active"
            : "show-up"
        }
        style={{
          position: "absolute",
          left: "3%",
          bottom: "13%",
          pointerEvents: bakeriesOnly ? "all" : "none",
        }}
      >
        <img alt="arrow" src={search} />
        <p className="button mainBlue">Explore messages</p>
      </Button>
      <div style={{ position: "absolute", left: "3%", bottom: "5%" }}>
        <ParticleEffectButton
          hidden={showMessageForm}
          color={Colors.white}
          direction="top"
        >
          <Button
            size="large"
            transparent
            onClick={() => setShowMessageForm(true)}
            className={
              exploreMode && !globeFocused
                ? "show-up show-order-12 active"
                : "show-up"
            }
          >
            <img alt="arrow" src={send} />
            <p className="button">Add New Message</p>
          </Button>
        </ParticleEffectButton>
      </div>
      <Logo />
      {showBakery && (
        <BakeryProfile
          bakery={bakeryInfo}
          close={() => {
            setShowBakery(false);
            globe.updateFocus(null);
          }}
          visible={!showMore}
          goToShowMore={() => setShowMore(true)}
        />
      )}
      {showBakery && (
        <BakeryMoreInfo
          bakery={bakeryInfo}
          visible={showMore}
          close={() => setShowMore(false)}
        />
      )}
      {showMessageForm && (
        <MessageOverlay
          close={() => setShowMessageForm(false)}
          recheckMarkers={fetchMarkers}
        />
      )}
      {globeFocused && selectedMessage && (
        <MessageOverlay
          isMarker={true}
          close={closeOverlays}
          messageInfo={selectedMessage}
          recheckMarkers={fetchMarkers}
        />
      )}
    </div>
  );
}

export default Globe;
