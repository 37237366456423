import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components/macro";
import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { animated } from "react-spring";

gsap.registerPlugin(SplitText);

let splitTextOut = null;

const Text = styled(animated.h2)`
  font-size: 68px;
  line-height: 79px;
  font-weight: normal;
  & .line {
    position: relative;
    overflow: hidden;
  }
`;

const RevealSplitText = ({
  wordDelay,
  className,
  children,
  showUI,
  wait = true,
}) => {
  const [timeline, setTimeline] = useState(null);
  const textRef = useRef();

  useEffect(() => {
    splitTextOut = new SplitText(textRef.current, {
      linesClass: "line",
      charsClass: "chars",
      wordsClass: "word",
    });

    if (splitTextOut && !timeline) {
      let tl = gsap.timeline({ paused: true }).fromTo(
        splitTextOut.words,
        { y: "-100%", opacity: 0 },
        {
          ease: "power3.inOut",
          delay: 0 + wordDelay,
          y: "0%",
          opacity: 1,
          duration: 1.4,
          stagger: 0.1,
        }
      );

      setTimeline(tl);
    } else if (splitTextOut && timeline && !wait) {
      timeline.play();
      timeline.delay(0 + wordDelay);
    }
  }, [timeline, wait]);

  useEffect(() => {
    if (timeline) {
      if (!showUI) {
        console.log("Reversing");
        timeline.reverse();
      } else {
        setTimeout(() => timeline.play(), 500);
      }
    }
  }, [showUI]);

  return (
    <Text className={className + " no-user-select"} ref={textRef}>
      {children}
    </Text>
  );
};

export default RevealSplitText;
