import { animated } from 'react-spring';
import styled from 'styled-components/macro';
import { Colors } from '../../utils/Colors';

export const BakeryProfileOverlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: url(${({ bg }) => bg}) no-repeat center;
  background-size: cover;
  transform: translateX(-100%);
  
  transition: opacity .7s .5s, transform .8s .3s;

  &.visible {
    transform: translateX(0%);
  }

  &.loaded {
    opacity: 1;
  }

  & .hide {
    opacity: 0;
    pointer-events: none;
  }

  & video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @media (max-height: 810px) {
    & .return-button {
      left: 60px !important;
    }
  }
`;

export const BakerySplash = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  left: 0;
  top: 0;
  max-width: 672px;
  width: 100%;
  height: 100%;
  padding: 90px;

  transition: opacity .6s;

  & * {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  & > * {
    z-index: 5;
    margin: 16px 0px;
  }

  & .meta-2 {
    margin: 0;
  }

  @media (max-height: 810px) {
    padding: 30px 60px;

    & > * {
      margin: 12px 0px;
    }

    p.description {
      font-size: 18px;
      line-height: 29px;
    }
  }
`;

export const SplashBacgkround = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(29,29,29,1) 0%, rgba(14,14,35,0) 100%);
  filter: blur(4px);
  z-index: 0 !important;
  margin: 0px;
`;

export const SideDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  max-width: 534px;
  height: 100%;
  background: ${Colors.fadedDarkBlue};
  box-shadow: 8px 0px 40px ${Colors.fadedDarkBlue};
  padding: 0px 90px;
  transform: translateX(0px);

  transition: transform .7s;
  transition-delay: 0.5s;

  &.slide {
    transform: translateX(450px);
    pointer-events: none;
    transition-delay: 0s !important;
  }

  @media (max-height: 810px) {
    h4 {
      font-size: 23px;
      line-height: 27px;
    }

    & .owner {
      margin-top: -1vh !important;
    }
  }
`;

export const FullGlobe = styled.img`
  height: 42vh;
  max-height: 390px;
`;

export const Quote = styled.h4`
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.06em;
`;

export const BakeryVideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;

  transition: opacity .7s .9s;

  &.ready {
    opacity: 1;
    transition: opacity .7s 0s;
  }

  &.hide {
    opacity: 0;
  }

  & .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;