import styled from 'styled-components/macro';
import { Corner } from '../../components/common';
import { Colors } from '../../utils/Colors';
import Loader from 'react-loader-spinner';

import playround from '../../assets/icons/playround.svg'
import { animated } from 'react-spring';

export const BakeryMoreInfoOverlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background: ${Colors.darkBlue};
  display: flex;
  transform: translateX(100%);

  transition: transform .8s .3s;

  &.visible {
    transform: translateX(0%);
  }
`;

export const InformationSide = styled.div`
  display: flex;
  width: 65%;
  height: 100%;

  @media (max-width: 1480px) {
    width: 70%;

    & > img:first-child {
      width: 45px;
      height: 45px;
    }
  }
`;

export const GlobeVitals = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 66px 0px;
  width: 45%;
  height: 100%;

  @media (max-width: 1480px) {
    width: 40%;
  }
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 66px;
  width: 55%;
  height: 100%;

  & > * {
    margin: 24px 0px;
  }

  & h2 {
    max-width: 424px;
  }

  & p {
    max-width: 491px;
  }

  @media (max-height: 810px) {
    & > * {
      margin: 10px 0px;
    }

    & h2 {
      font-size: 45px;
      line-height: 50px;
    }

    & .small {
      font-size: 15px;
      line-height: 24px;
    }
  }

  @media (max-width: 1480px) {
    width: 60%;

    padding: 30px;
  }
`;

export const PreviewBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  min-height: 246px;
  cursor: pointer;

  & #interview-preview {
    position: absolute;
    overflow: hidden;
    z-index: 0;
    height: 86%;
    width: 93%;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  @media (max-height: 650px) {
    width: 244px;
    min-height: 140px;
  }
`;

export const InterviewPreviewBox = ({ image, playing, noIcon, ...props }) => {
  return (
    <PreviewBox {...props}>
      <div id="interview-preview">
        <img alt="preview" src={image} />
      </div>
      {noIcon
        ? null
        : !playing
          ? <img alt="play" src={playround} style={{ position: 'relative', zIndex: 1 }} />
          : <Loader type="TailSpin" color={Colors.yellow} height={'25px'} width={'25px'} style={{ position: 'relative', zIndex: 1 }} />}
      <Corner style={{ position: 'absolute', top: 0, left: 0 }} />
      <Corner style={{ position: 'absolute', bottom: 0, left: 0, transform: 'rotate(-90deg)' }} />
      <Corner style={{ position: 'absolute', bottom: 0, right: 0, transform: 'rotate(-180deg)' }} />
      <Corner style={{ position: 'absolute', top: 0, right: 0, transform: 'rotate(90deg)' }} />
    </PreviewBox>
  )
}

export const GallerySide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 100%;
  background: url(${({ bg }) => bg}) no-repeat center;
  background-size: cover;

  & img {
    position: absolute;
    opacity: 0;

    transition: opacity .7s;
  }

  & #gallery-1 {
    top: 31px;
    left: -31px;
  }

  & #gallery-2 {
    top: 85px;
    right: -20px;
  }

  & #gallery-4 {
    left: -70px;
    bottom: 80px;
  }

  & .revealed {
    opacity: 1;
  }

  @media (max-width: 1480px) {
    width: 30%;
    
    & img {
      max-width: 100%;
    }

    & #gallery-1 {
      top: 31px;
      left: 25px;
    }

    & #gallery-2 {
      top: 85px;
      right: 10px;
    }

    & #gallery-4 {
      left: -20px;
      bottom: 20px;
    }
  }
`;

export const GlobeCorner = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
`;

export const Vitals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 346px;

  & img {
    margin-bottom: 20px;
  }

  @media (max-width: 1480px) {
    width: 61%;
  }
`

const VitalLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 346px;
  border-bottom: 1px solid rgb(245 246 255 / 20%);
  padding: 16px 0px;
`

export const VitalLine = ({ legend, vital }) => {
  return (
    <VitalLineWrapper>
      <p className="meta-2 lightblue" style={{ opacity: 0.4 }}>{legend}</p>
      <p className="small" style={{ textAlign: 'right' }}>{vital}</p>
    </VitalLineWrapper>
  )
}

export const InterviewVideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0 ,0, 0.6);
  opacity: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity .7s .9s;

  &.ready {
    opacity: 1;
    transition: opacity .7s 0s;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  & .video-player {
    width: 800px;
  }
`;