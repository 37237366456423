import React from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../utils/Colors';

import logo from '../assets/images/connecting_logo.png'

const Block = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${Colors.darkBlue};
  z-index: 1000;
  display: ${({ overrideShow }) => overrideShow ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;

  & > * {
      margin: 20px 0px;
  }

  & img {
      max-width: 100%;
  }

  @media(max-width: 1200px) {
      display: flex;
  }
`;

const MobileBlock = ({ text, overrideShow }) => {
    return ( 
        <Block overrideShow={overrideShow}>
            <img alt="logo" src={logo} />
            <h5>{text || 'Sorry, this experience is built for desktop only. Please switch to a bigger screen to explore iba.COMMUNITY.'}</h5>
        </Block>
     );
}
 
export default MobileBlock;