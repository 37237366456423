import React, { useState, useContext } from "react";
import ParticleEffectButton from "react-particle-effect-button";
import { FlexContainer } from "../../components/common";
import { FormSide, Form, SuccessMessage, FormContainer } from "./MessageOverlay.styles";
import { Input, LocationInput, TextArea, ImageInput, Checkbox } from "../../components/Inputs";
import { Button } from "../../components/Buttons";
import { AudioContext } from "../../contexts/AudioContext";

import heartsquare from "../../assets/icons/hearg_square.svg";
import send from "../../assets/icons/send.svg";
import checkgreen from "../../assets/icons/checkgreen.svg";
import { Colors } from "../../utils/Colors";
import { playSound, validURL, checkEmailValidity, getCityFromGoogleData } from "../../utils/helpers";

const MessageForm = ({
  form,
  setForm,
  bind,
  sendMessage,
  sent,
  close,
  formOpen,
  replyMode,
  messageInfo,
  sendReply,
  ...props
}) => {
  const [sendInitiated, setSendInitiated] = useState(false);
  const [animationDone, setAnimationDone] = useState(false);
  const [audio] = useContext(AudioContext);

  const validate = (frm) => {
    if (replyMode) {
      if (
        frm.name.length > 0 &&
        frm.message.length &&
        frm.agb &&
        (form.email.length && checkEmailValidity(form.email))
        ) 
      {
        return false;
      } else return true;
    } else {
      if (
        frm.name.length > 0 &&
        frm.location &&
        frm.message.length &&
        frm.agb &&
        (frm.linkedin.length ? (validURL(frm.linkedin) ? true : false) : true) &&
        (form.allowMessage && form.email.length
          ? checkEmailValidity(form.email)
            ? true
            : false
          : form.allowMessage && form.email.length === 0 ? false : true)
      ) {
        return false;
      } else return true;
    }
  };

  const name = messageInfo && !Array.isArray(messageInfo) ? messageInfo.name : ''

  return (
    <FormSide formOpen={formOpen} {...bind()} {...props}>
      {replyMode ?
        <FormContainer>
          <FlexContainer
            size="none"
            justify="flex-start"
            style={{ width: "100%" }}
          >
            <img alt="heart" src={heartsquare} style={{ width: 56 }} />
            <h4 style={{ marginLeft: 20, fontWeight: "bold" }} className="mainBlue">
              Start a conversationwith <span className="yellow">{name}</span>!
            </h4>
          </FlexContainer>
          {sent && animationDone ? null : <p className="small" style={{ marginTop: 15, color: Colors.gray }}>We’ll send {name} an e-mail with your reply. You will then be able to continue the conversation in a regular e-mail thread.</p>}
          {sent && animationDone ? (
            <SuccessMessage>
              <img alt="check" style={{ marginTop: '5vh' }} src={checkgreen} />
              <h5 className="mainBlue">
                Done! Your message has been successfully sent via e-mail!
              </h5>
              <p className="small gray">We sent an e-mail to {name} with your message. Once he replies, you’ll be able to continue the conversation in a regular e-mail thread.</p>
              <Button
                size="small"
                style={{
                  alignSelf: "center",
                  border: "1px solid " + Colors.mainBlue,
                  marginTop: "30%",
                }}
                transparent
                onClick={close}
              >
                <p className="button mainBlue">Return to Globe</p>
              </Button>
            </SuccessMessage>
          ) : (
            <>
              {/* <p className="small gray">Your message will be pinned on the iba Globe for 8 hours. It will be visible to other visitors, who will also able to send you a direct response via e-mail.</p> */}
              <Form
                direction="column"
                size="none"
                align="flex-start"
                justify="flex-start"
              >
                  <Input
                    label="Username or nickname"
                    placeholder="Who are you?"
                    value={form.name}
                    setValue={(value) => setForm({ ...form, name: value })}
                  />
                  <Input
                    label="E-mail"
                    placeholder="What’s your e-mail address?"
                    value={form.email}
                    setValue={(value) => setForm({ ...form, email: value })}
                    validateError="This field requires a valid e-mail address"
                    validate={
                      form.email.length
                        ? checkEmailValidity(form.email)
                        : null
                    }
                  />
                <TextArea
                  label="Your Response"
                  placeholder={"What would you like to say to " + name + "?"}
                  value={form.message}
                  setValue={(value) => setForm({ ...form, message: value })}
                />
                <Checkbox
                  link="https://www.iba.de/en/data-protection/"
                  label="I agree with iba’s Terms & Conditions and Privacy Policies."
                  value={form.agb}
                  setValue={(value) => setForm({ ...form, agb: value })}
                />
              </Form>
              <ParticleEffectButton
                hidden={sendInitiated}
                color={Colors.yellow}
                direction="top"
                onComplete={() => setAnimationDone(true)}
              >
                <Button
                  size="medium"
                  style={{ alignSelf: "center" }}
                  onClick={() => {
                    if (!sendInitiated) {
                      playSound("particle_buttons", audio, 1);
                      sendReply()
                    }
                    setSendInitiated(true);
                  }}
                  disabled={validate(form)}
                >
                  <p className="button mainBlue">Send Direct Reply</p>
                  <img alt="arrow" src={send} />
                </Button>
              </ParticleEffectButton>
            </>
          )}
        </FormContainer>
      :
        <FormContainer>
          <FlexContainer
            size="none"
            justify="flex-start"
            style={{ width: "100%" }}
          >
            <img alt="heart" src={heartsquare} style={{ width: 56 }} />
            <h4 style={{ marginLeft: 20, fontWeight: "bold" }} className="mainBlue">
              Post a message to the globe!
            </h4>
          </FlexContainer>
          {sent && animationDone ? (
            <SuccessMessage>
              <img alt="check" src={checkgreen} />
              <h5 className="mainBlue">
                Done! Your message has been successfully added to the globe!
              </h5>
              <p className="small gray">Your message will be pinned on the iba Globe for 8 hours. It will be visible to other visitors, who will also able to send you a direct response via e-mail.</p>
              <Button
                size="small"
                style={{
                  alignSelf: "center",
                  border: "1px solid " + Colors.mainBlue,
                  marginTop: "30%",
                }}
                transparent
                onClick={close}
              >
                <p className="button mainBlue">Return to Globe</p>
              </Button>
            </SuccessMessage>
          ) : (
            <>
              {/* <p className="small gray">Your message will be pinned on the iba Globe for 8 hours. It will be visible to other visitors, who will also able to send you a direct response via e-mail.</p> */}
              <Form
                direction="column"
                size="none"
                align="flex-start"
                justify="flex-start"
              >
                <FlexContainer size="none" justify="space-between">
                  <Input
                    label="Username or nickname"
                    placeholder="Who are you?"
                    value={form.name}
                    setValue={(value) => setForm({ ...form, name: value })}
                  />
                  <LocationInput
                    label="City"
                    placeholder="Where are you?"
                    setValue={(location, latLng) =>
                      setForm({
                        ...form,
                        location: location,
                        latLng: latLng,
                        city: getCityFromGoogleData(location),
                      })
                    }
                  />
                </FlexContainer>
                <FlexContainer size="none" justify="space-between">
                  <Input
                    label="Bakery Specialty (recommended)"
                    placeholder="What is trending at your bakery?"
                    value={form.trend}
                    setValue={(value) => setForm({ ...form, trend: value })}
                    maxLength="25"
                  />
                  <Input
                    label="LinkedIn (recommended)"
                    placeholder="LinkedIn account URL"
                    value={form.linkedin}
                    setValue={(value) => setForm({ ...form, linkedin: value })}
                    validateError="This field requires a URL from LinkedIn"
                    validate={
                      form.linkedin.length ? validURL(form.linkedin) : null
                    }
                  />
                </FlexContainer>
                <TextArea
                  label="Message"
                  placeholder="What would you like to say to the iba community? We know you have lots to share, but try to keep it short – 160 characters max!"
                  value={form.message}
                  setValue={(value) => setForm({ ...form, message: value })}
                />
                <ImageInput
                  label="Image to appear with your message (recommended)"
                  value={form.image}
                  setValue={(value, file) => {
                    console.log(value, file);
                    setForm({ ...form, imagePreview: value, image: file });
                  }}
                />
                <Checkbox
                  link="https://www.iba.de/en/data-protection/"
                  label="I agree with iba’s Terms & Conditions and Privacy Policies."
                  value={form.agb}
                  setValue={(value) => setForm({ ...form, agb: value })}
                />
                <Checkbox
                  label="I want to network and be contacted by other users via e-mail."
                  value={form.allowMessage}
                  setValue={(value) => setForm({ ...form, allowMessage: value })}
                />
                {form.allowMessage ? (
                  <Input
                    label="E-mail"
                    placeholder="What’s your e-mail address?"
                    value={form.email}
                    setValue={(value) => setForm({ ...form, email: value })}
                    validateError="This field requires a valid e-mail address"
                    validate={
                      form.allowMessage && form.email.length
                        ? checkEmailValidity(form.email)
                        : null
                    }
                  />
                ) : null}
              </Form>
              <ParticleEffectButton
                hidden={sendInitiated}
                color={Colors.yellow}
                direction="top"
                onComplete={() => setAnimationDone(true)}
              >
                <Button
                  size="medium"
                  style={{ alignSelf: "center" }}
                  onClick={() => {
                    if (!sendInitiated) {
                      playSound("particle_buttons", audio, 1);
                      sendMessage()
                    }
                    setSendInitiated(true);
                  }}
                  disabled={validate(form)}
                >
                  <p className="button mainBlue">Add new message</p>
                  <img alt="arrow" src={send} />
                </Button>
              </ParticleEffectButton>
            </>
          )}
        </FormContainer>
      }
    </FormSide>
  );
};

export default MessageForm;
