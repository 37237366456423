import React, { useEffect, useState } from "react";
import { useMove } from "react-use-gesture";
import { useSpring } from "react-spring";

import {
  FormSideClickable,
  MessageOverlayContainer,
} from "./MessageOverlay.styles";
import MessageForm from "./MessageForm";
import MessagePanel from "./MessagePanel";

import { postMessage, sendReply } from "../../services/messageService";
import ReturnButton from "../../components/ReturnButton";

const MessageOverlay = ({
  close,
  isMarker = false,
  messageInfo,
  recheckMarkers,
}) => {
  const [reveal, setReveal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [sent, setSent] = useState(false);
  const [specificReplyFromList, setSpecificReplyFromList] = useState(null);
  const [form, setForm] = useState({
    name: "",
    location: null,
    latLng: null,
    trend: "",
    city: null,
    linkedin: "",
    message: "",
    imagePreview: null,
    image: null,
    agb: false,
    allowMessage: false,
    email: "",
  });

  const [{ xy }, set] = useSpring(() => ({ xy: [1500, 400] }));

  const bind = useMove(({ xy }) => {
    set({ xy });
  });

  const sendMessage = async () => {
    let response = await postMessage(form);
    console.log(response);
    setSent(true);
    recheckMarkers();
  };

  const reply = async () => {
    let body = {
      message: form,
      recipient: specificReplyFromList
        ? specificReplyFromList._id
        : messageInfo._id,
    };

    try {
      let response = await sendReply(body);
      console.log(response);
      setSent(true);
    } catch (e) {
      console.log(e);
    }
  };

  const clearOverlay = () => {
    setReveal(false);
    setShowForm(false);
    setTimeout(() => close(), isMarker ? 500 : 2100);
  };

  const onBakerySelected = () => {
      console.log('Bakery Selcted', reveal, showForm)
      clearOverlay();
  }

  useEffect(() => {
    setTimeout(() => setReveal(true), 200);

    window.addEventListener('bakery-selected', onBakerySelected);

    return () => window.removeEventListener('bakery-selected', onBakerySelected);
  }, []);

  return (
    <MessageOverlayContainer>
      <ReturnButton
        close={clearOverlay}
        className={
          reveal ? "appear active show-order-8" : "appear show-order-8"
        }
      />
      <MessagePanel
        specificReplyFromList={specificReplyFromList}
        setSpecificReplyFromList={setSpecificReplyFromList}
        replyMode={replyMode}
        setReplyMode={setReplyMode}
        isMarker={isMarker}
        form={form}
        showForm={showForm}
        setShowForm={setShowForm}
        messageInfo={messageInfo}
        className={reveal ? "visible" : ""}
        reveal={reveal}
        close={clearOverlay}
      />
      {/* {isMarker && !showForm && reveal 
                ? <FormSideClickable onClick={clearOverlay} />
                : <MessageForm sendReply={reply} replyMode={replyMode} formOpen={!isMarker || showForm} form={form} setForm={setForm} bind={bind} sendMessage={sendMessage} sent={sent} close={clearOverlay} className={(!isMarker || showForm) && reveal ? "visible" : ""} messageInfo={specificReplyFromList ? specificReplyFromList : messageInfo} />
            } */}
      {/* {isMarker && !showForm && reveal && <FormSideClickable onClick={clearOverlay} />} */}
      <MessageForm
        sendReply={reply}
        replyMode={replyMode}
        formOpen={!isMarker || showForm}
        form={form}
        setForm={setForm}
        bind={bind}
        sendMessage={sendMessage}
        sent={sent}
        close={close}
        className={(!isMarker || showForm) && reveal ? "visible" : ""}
        messageInfo={
          specificReplyFromList ? specificReplyFromList : messageInfo
        }
      />
    </MessageOverlayContainer>
  );
};

export default MessageOverlay;
