import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../utils/Colors';

import defaultText from '../assets/images/round_button_text.png'
import play from '../assets/icons/play.png'
import { animated } from 'react-spring';

const RoundBackground = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -60px;
  left: -60px;
  width: 120px;
  height: 120px;
  background: ${Colors.yellow};
  border-radius: 500px;
  pointer-events: none;
  opacity: 1;

  transition: opacity .4s .6s;

  & #play-button {
      position: absolute;
  }

  &.first-show {
    transition: opacity .4s .1s;
  }

  &.hide {
      opacity: 0;
  }
`;

const CursorButton = ({ active, text, icon, ...props }) => {
    const [firstTimeActive, setFirstTimeActive] = useState(false)

    useEffect(() => {
        if (active && !firstTimeActive) setFirstTimeActive(true)
    }, [active])

    return ( 
        <RoundBackground className={`${active ? '' : 'hide'} ${firstTimeActive ? 'first-show' : ''}`} {...props}>
            <img alt="button-text" src={text || defaultText} id="round-text" />
            <img alt="play-icon" src={icon || play} id="play-button" />
        </RoundBackground>
     );
}
 
export default CursorButton;