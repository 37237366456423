import React, { useEffect, useRef, useState } from "react";
import { animated } from "react-spring";
import { detectOrientation } from "../utils/helpers";

const Image = ({
  Component,
  simple,
  fitFromOrientation,
  hide,
  children,
  ...props
}) => {
  const ref = useRef(null);
  const [orientation, setOrientation] = useState("landscape");

  const onLoad = (e) => {
    console.log(e.target.complete)
    setOrientation(detectOrientation(e.target));
    e.target.style.opacity = 1;
  };

  const styleFromOrientation = (e) => {
    if (fitFromOrientation) {
      if (orientation === "landscape") {
        return { opacity: 0, height: "100%" };
      } else if (orientation === "portrait") {
        return { opacity: 0, width: "100%" };
      } else return { opacity: 0, width: "100%" };
    } else return { opacity: 0 };
  };

  useEffect(() => {
    if (ref.current?.complete) {
      ref.current.style.opacity = 1;
    }
  }, []);

  useEffect(() => {
    if (hide) {
      ref.current.style.opacity = 0;
    }
  }, [hide]);

  if (Component) {
    return <Component {...props} ref={ref} onLoad={(e) => onLoad(e)} />;
  } else if (simple) {
    return (
      <animated.img
        {...props}
        ref={ref}
        style={styleFromOrientation()}
        onLoad={(e) => onLoad(e)}
      />
    );
  } else {
    return (
      <animated.picture ref={ref} {...props} onLoad={(e) => onLoad(e)}>
        {children}
      </animated.picture>
    );
  }
};

export default Image;
