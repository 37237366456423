import styled, {css} from 'styled-components/macro'
import { Colors } from '../utils/Colors'

import logo from '../assets/images/logo.png'

export const FlexContainer = styled.div`
  width: ${({ fullWidth }) => fullWidth ? '100%' : null};
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "center"};
  flex: ${({ size }) => size || "auto"};
  
  ${({ wrap }) => wrap && css`
    flex-wrap: ${({ wrap }) => wrap};
  `}

  ${({ relative }) => relative && css`
    position: relative;
  `}
`

const CornerWrapper = styled.div`
  width: 8px;
  height: 8px;
  position: relative;
`;

const LogoWrapper = styled.div`
  position: absolute;
  right: 3%;
  bottom: 5%;
`;

const HighlightBoxWrapper = styled.span`
  position: relative;
  border: 1px solid #8F8F9C;
  padding: 0px 10px;
  cursor: pointer;
`

export const Corner = ({ ...props }) => {
    return ( 
        <CornerWrapper {...props}>
            <div style={{ width: '100%', height: 1, background: Colors.yellow, position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ height: '100%', width: 1, background: Colors.yellow, position: 'absolute', top: 0, left: 0 }}></div>
        </CornerWrapper>
     );
}

export const HighlightBox = ({ children, ...props }) => {
    return ( 
        <HighlightBoxWrapper {...props}>
            <Corner style={{ position: 'absolute', top: 0, left: 0 }} />
            <Corner style={{ position: 'absolute', bottom: 0, left: 0, transform: 'rotate(-90deg)' }} />
            <Corner style={{ position: 'absolute', bottom: 0, right: 0, transform: 'rotate(-180deg)'}} />
            <Corner style={{ position: 'absolute', top: 0, right: 0, transform: 'rotate(90deg)' }} />
            {children}
        </HighlightBoxWrapper>
     );
}

export const Logo = ({ ...props }) => {
    return ( 
        <LogoWrapper {...props}>
            <img alt="logo" src={logo} />
        </LogoWrapper>
     );
}