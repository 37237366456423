import React from "react";
import styled from "styled-components/macro";
import { FlexContainer } from "./common";
import { Colors } from "../utils/Colors";

import Image from "../components/Image";

import linkedin from "../assets/icons/linkedin.svg";
import reply from "../assets/icons/reply.svg";
import avatar from "../assets/icons/avatar.svg";
import closeicon from "../assets/icons/close_round.svg";
import placeholder from "../assets/images/ibaConnect2020_msg_def.jpg";
import Loader from "react-loader-spinner";
import { validURL } from "../utils/helpers";

const MessageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 819px;
  width: 100%;
  height: 236px;
  margin: 22px 0px;

  & * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  @media (max-width: 1400px) {
    height: 270px;
  }
`;

const MessageCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 651px;
  width: 80%;
  height: 220px;
  background-color: #141418;
  box-shadow: -40px 51px 80px rgba(0, 0, 0, 0.5);
  padding: 24px 50px;

  & > a {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  & .reply {
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }

  @media (max-width: 1600px) {
    padding-left: 5vw;
  }

  @media (max-width: 1400px) {
    padding: 24px 30px;
    padding-left: 8vw;
    height: 253px;
  }
`;

const MessageImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 200px;
  height: 200px;
  z-index: 1;
  background-color: ${Colors.gray};

  & img {
    z-index: 1;

    transition: opacity 0.4s;
  }

  & img.avatar-picture {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  & .input-1 {
    margin-top: 10px;
    max-width: 100px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 538px;
  width: 100%;
  height: 100%;
  margin-left: 10px;

  h6 {
    font-family: "Sectra";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.04em;
    margin: 0;
  }

  & .message-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    bottom: 24px;
    max-width: 68%;
  }

  @media (max-width: 1400px) {
    & .message-box > * {
      margin-right: 17px !important;
      max-width: 50%;
      align-items: flex-start;
    }

    & .message-box .smaller {
      line-height: 16px;
    }
  }
`;

const MetaDataLine = ({ title, content }) => {
  return (
    <FlexContainer size="none" justify="flex-start" style={{ marginRight: 32 }}>
      <p className="meta-4 lightblue" style={{ marginRight: 5 }}>
        {title}
      </p>
      <p className="smaller">{content}</p>
    </FlexContainer>
  );
};

export const MessageQuote = ({
  image,
  message,
  messanger,
  location,
  trend,
  linkedIn,
  close,
  formMode,
  allowMessage,
  openReply,
  ...props
}) => {
  return (
    <MessageContainer {...props}>
      {close ? (
        <img
          alt="close"
          src={closeicon}
          style={{
            position: "absolute",
            top: 0,
            right: -15,
            zIndex: 1,
            cursor: "pointer",
          }}
          onClick={close}
        />
      ) : null}
      <MessageImage>
        {image ? (
          <>
            <Loader
              type="TailSpin"
              color={Colors.yellow}
              height={"25px"}
              width={"25px"}
              style={{ position: "absolute", zIndex: "0" }}
            />
            <Image
              src={image}
              alt="message"
              id="avatar-picture"
              simple
              fitFromOrientation
            />
          </>
        ) : !formMode ? (
          <Image
            src={placeholder}
            alt="message"
            id="avatar-picture"
            simple
            fitFromOrientation
          />
        ) : (
          <>
            <img alt="avatar" src={avatar} />
            <p className="input-1">Your image will appear here.</p>
          </>
        )}
      </MessageImage>
      <MessageCard>
        {linkedIn && linkedIn.length && validURL(linkedIn) ? (
          <a href={linkedIn} target="__blank">
            <img alt="LinkedIn" src={linkedin} />
          </a>
        ) : null}
        {allowMessage ? (
          <img alt="reply" src={reply} className="reply" onClick={openReply} />
        ) : null}
        <Message>
          <p className="small yellow">{messanger || "Username or nickname"}</p>
          <h6>
            {message ||
              "Your message will appear here as you start filling up the form. Join iba’s global conversation now!"}
          </h6>
          <div className="message-box">
            <MetaDataLine title="location" content={location || "..."} />
            {trend || formMode ? (
              <MetaDataLine title="trend" content={trend || "..."} />
            ) : null}
          </div>
        </Message>
      </MessageCard>
    </MessageContainer>
  );
};
