import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";
import bg from "../assets/video/iba-globe-background-faded.mp4";

let spotlightSize = "transparent 18px, rgba(0, 0, 0, 0.35) 600px)";

const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: url(${({ image }) => image}) no-repeat center; */
  background-size: cover;
  cursor: none;

  /* ${({ active }) =>
    !active &&
    css`
      display: none;
    `} */
`;

const Spotlight = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
    circle,
    transparent 18px,
    rgba(0, 0, 0, 0.35) 600px
  );
`;

const SpotlightBackground = ({ image, active, videoClass, ...props }) => {
  let spotlight = useRef();

  const updateSpotlight = (e) => {
    if (active && spotlight.current)
      spotlight.current.style.backgroundImage = `radial-gradient(circle at ${
        (e.pageX / window.innerWidth) * 100
      }% ${(e.pageY / window.innerHeight) * 100}%, ${spotlightSize}`;
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateSpotlight);

    return () => {
      window.removeEventListener("mousemove", updateSpotlight);
    };
  }, []);

  return (
    <>
      <video
        id="main-intro-video"
        className={videoClass}
        src={bg}
        autoPlay
        loop
        muted
      />
      <Background {...props} image={image} active={active}>
        <Spotlight ref={spotlight} />
      </Background>
    </>
  );
};

export default SpotlightBackground;
