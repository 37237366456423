import React, { useState, createContext } from "react";

// Create Context Object
export const StageContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const StageContextProvider = (props) => {
  const [stage, setStage] = useState("intro");

  return (
    <StageContext.Provider value={[stage, setStage]}>
      {props.children}
    </StageContext.Provider>
  );
};
