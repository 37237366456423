import Axios from "axios";
import { endpoint } from "../utils/helpers";

export const postMessage = async (body) => {
  console.log("Posting ", body);
  let imageRes;

  try {
    if (body.image) {
      const form = new FormData();
      form.append("image", body.image);
      imageRes = await Axios.post(endpoint() + "/messages/upload", form, {
        headers: { "Content-Type": "image/jpg" },
      });
      body.image = imageRes.data.link;
    }

    return await Axios.post(endpoint() + "/messages/new", body);
  } catch (e) {
    console.log(e);
  }
};

export const sendReply = async (body) => {
  console.log("Replying ", body);

  return await Axios.post(endpoint() + "/messages/reply", body);
};
