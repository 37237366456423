import React, { useContext, useEffect } from 'react';
import MobileBlock from './components/MobileBlock';
import { StageContext } from './contexts/StageContext';
import Globe from './modules/Globe/Globe';
import Intro from './modules/Intro/Intro';
import { supportsWebGL } from './utils/helpers';

function Experience() {
  const [stage, setStage] = useContext(StageContext);

  useEffect(() => {
    if (!supportsWebGL()) {
      setStage('WEBGLERROR')
    } 

    if (window.location.hash && window.location.hash === '#globe-bakeries') {
      setStage('globe')
    }
  }, [setStage])

  return (
    <div className="App">
      <MobileBlock />
      {stage === 'intro' && <Intro />}
      {stage === 'globe' && <Globe />}
      {stage === 'WEBGLERROR' && <MobileBlock text="Sorry, your browser or computer do not seem to support this experience." overrideShow />}
    </div>
  );
}

export default Experience;
