import React, { useState, useContext } from 'react';
import ReactPlayer from 'react-player/vimeo'
import { useMove } from 'react-use-gesture';
import { useSpring } from 'react-spring';
import { parseHighlight } from '../../utils/helpers';

import { AudioContext } from "../../contexts/AudioContext";

import { FlexContainer } from '../../components/common';
import { BakeryMoreInfoOverlay, InterviewVideoContainer, GallerySide, InformationSide, GlobeVitals, MainInfoContainer, GlobeCorner, Vitals, VitalLine, InterviewPreviewBox } from './BakeryMoreInfo.styles';
import CursorButton from '../../components/CursorButton';

import globecorner from '../../assets/images/profile/globecorner.png'
import returnIcon from '../../assets/icons/return.svg';
import closeIcon from '../../assets/icons/close.svg';
import clickblue from '../../assets/icons/clickblue.svg';
import explorecircle from '../../assets/images/explorecircle.png';

let notPlayingInterviewTimeout;

const BakeryMoreInfo = ({ bakery, close, visible }) => {
    const [, setAudio] = useContext(AudioContext);
    const [firstPlay, setFirstPlay] = useState(false);
    const [galleryCount, setGalleryCount] = useState(0)
    const [playInterview, setPlayInterview] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const [fadePlayer, setFadePlayer] = useState(true);
    const [{ xy }, set] = useSpring(() => ({ xy: [1500, 400] }))
    const [notPlayingError, setNotPlayingError] = useState(false);

    const onCloseReturn = () => {
        close()
    }

    const onStartVideoPlayer = () => {
        console.log('Starting Video Player')
        setFadePlayer(false)
        setPlayInterview(true)
        console.log('Fade Player ', fadePlayer, '. Play Tour ', playInterview)
        notPlayingInterviewTimeout = setTimeout(() => {
            console.log('After timeout, Fade Player ', fadePlayer, '. Play Tour ', playInterview)
            if (playInterview === false) {
                setPlayInterview(true)
                console.log('Force Show the Player because playInterview is ', playInterview)
                setNotPlayingError(true)
                setShowPlayer(true)
                setAudio(false)
            }
        }, 2500)
    }

    const onCloseVideoPlayer = () => {
        setFadePlayer(true)
        setShowPlayer(false)
        setTimeout(() => {
            setPlayInterview(false)
            setAudio(true)
        }, 1500)
    }

    const onVideoPlayed = () => {
        clearTimeout(notPlayingInterviewTimeout)
        console.log('Video has played successfully. Message from React-Player.')
        setShowPlayer(true)
        setAudio(false)
        setNotPlayingError(false)
    }

    const addToGallery = () => {
        let count = galleryCount

        if (count < 4) {
            setGalleryCount(count + 1)
        } else setGalleryCount(0)
    }

    const bind = useMove(({ xy }) => {
        set({ xy })
    })

    console.log(bakery)

    if (!bakery.gallery) return null;

    return ( 
        <BakeryMoreInfoOverlay className={visible ? 'visible' : ''}>
            {firstPlay ? 
            playInterview  && 
            (<InterviewVideoContainer className={showPlayer ? 'ready' : fadePlayer ? 'hide' : ''}>
                <img alt="close" src={closeIcon} style={{ position: 'absolute', top: '3%', left: '5%', cursor: 'pointer', zIndex: 20 }} onClick={onCloseVideoPlayer} />
                <ReactPlayer 
                    className="video-player"
                    url={bakery.interviewVideo || "https://vimeo.com/355320109/4f03ca8d6f"}           
                    controls={true}
                    playing={playInterview}
                    onReady={() => console.log('Video Ready')}
                    onStart={onVideoPlayed}
                    onEnded={onCloseVideoPlayer}
                    width="1280px"
                    height="720px"
                />
            </InterviewVideoContainer>)
            :
            visible  && 
            (<InterviewVideoContainer className={showPlayer ? 'ready' : fadePlayer ? 'hide' : ''}>
                <img alt="close" src={closeIcon} style={{ position: 'absolute', top: '3%', left: '5%', cursor: 'pointer', zIndex: 20 }} onClick={onCloseVideoPlayer} />
                <ReactPlayer 
                    className="video-player"
                    url={bakery.interviewVideo || "https://vimeo.com/355320109/4f03ca8d6f"}           
                    controls={true}
                    playing={playInterview}
                    onPlay={onVideoPlayed}
                    width="1280px"
                    height="720px"
                />
            </InterviewVideoContainer>)
            }
            <InformationSide>
                <img alt="close" src={returnIcon} style={{ position: 'absolute', top: '3%', left: '5%', cursor: 'pointer', zIndex: 5 }} onClick={onCloseReturn} />
                <GlobeVitals>
                    <GlobeCorner alt="globe" src={bakery.halfGlobe || globecorner} />
                    <Vitals>
                        {bakery.vitals ? Object.entries(bakery.vitals[0]).map(vital => {
                            if (vital[0] === '_id') {
                                return null
                            } else return <VitalLine key={vital[0]} legend={vital[0]} vital={[vital[1]]} />
                        }) : null}
                    </Vitals>
                </GlobeVitals>
                <MainInfoContainer>
                    <h2>{bakery.interviewHeadline}</h2>
                    <p className="small">{bakery.interviewDescription}</p>
                    <InterviewPreviewBox image={bakery.interviewImage} noIcon={bakery.interviewVideo === ""} onClick={bakery.interviewVideo === "" ? null : onStartVideoPlayer} playing={playInterview} />
                    {bakery.interviewVideo !== "" && (<FlexContainer direction="column" align="flex-start" size="none">
                        <p>{bakery.representative}</p>
                        <p className="meta-3 yellow">{bakery.role}</p>
                    </FlexContainer>)}
                </MainInfoContainer>
            </InformationSide>
            <GallerySide {...bind()} bg={bakery.gallery[0]} onClick={addToGallery}>
                {bakery.gallery.map((image, i) => {
                    return(
                        i > 0 ? <img key={"gallery-" + i} id={"gallery-" + i} className={galleryCount >= i ? 'revealed' : ''} alt="gallery" src={image} /> : null
                    )
                })}
            </GallerySide>
            <CursorButton text={explorecircle} icon={clickblue} active={!showPlayer} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px, ${y}px, 0)`) }} />
        </BakeryMoreInfoOverlay>
     );
}
 
export default BakeryMoreInfo;