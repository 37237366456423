// Packages Imports
import React from 'react';
import ReactDOM from 'react-dom';

// CSS Imports
import './index.css';
import './typography.css';

// Components
import Experience from './Experience';
import reportWebVitals from './reportWebVitals';
import { StageContextProvider } from './contexts/StageContext';
import { AudioContextProvider } from './contexts/AudioContext';

ReactDOM.render(
  // <React.StrictMode>
    <StageContextProvider>
      <AudioContextProvider>
        <Experience />
      </AudioContextProvider>
    </StageContextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
