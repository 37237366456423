import React, { useContext, useState, useRef, useEffect } from "react";
import ParticleEffectButton from "react-particle-effect-button";
import lottie from "lottie-web";

import { Button } from "../../components/Buttons";
import { DragAnimation, IntroContainer, TitleCard } from "./Intro.styles";
import { Logo } from "../../components/common";
import SpotlightBackground from "../../components/SpotlightBackground";
import { StageContext } from "../../contexts/StageContext";
import { AudioContext } from "../../contexts/AudioContext";
import { Colors } from "../../utils/Colors";
import { playSound } from "../../utils/helpers";

import logo from "../../assets/images/logo_big.svg";
import grid from "../../assets/images/imageGrid.png";
import award from "../../assets/images/cssda-wotd-white.png";
import arrow from "../../assets/icons/rounded.svg";
import animation from "../../assets/animations/iba_tutorial_data.json";
import SoundButton from "../../components/SoundButton";

const Intro = () => {
  const [, setStage] = useContext(StageContext);
  const lottieContainer = useRef();
  const [reveal, setReveal] = useState(false);
  const [gridLoaded, setGridLoaded] = useState(false);
  const [globeLoaded, setGlobeLoaded] = useState(false);
  const [tutorialActive, setTutorialActive] = useState(false);
  const [transitionToGlobe, setToTransitionToGlobe] = useState(false);
  const [audio] = useContext(AudioContext);

  const startTutorial = () => {
    playSound("particle_buttons", audio, 1);
    setTutorialActive(true);
    setTimeout(() => setReveal(false), 100);
    setTimeout(() => playSound("globe_reveal", audio), 800);
    setTimeout(() => playSound("main_audio", audio, null, 0.1), 13000);
  };

  const hideVideo = () => {
    console.log("globe loaded");
    setGlobeLoaded(true);
  };

  useEffect(() => {
    let anim;

    if (tutorialActive) {
      setTimeout(() => {
        anim = lottie.loadAnimation({
          container: lottieContainer.current,
          renderer: "svg",
          autoplay: true,
          animationData: animation,
          loop: false,
        });

        anim.onComplete = () => {
          setToTransitionToGlobe(true);
          setTimeout(() => setStage("globe"), 1000);
        };
      }, 1200);
    }

    return () => (anim ? anim.destroy() : null); // optional clean up for unmounting
  }, [tutorialActive]);

  useEffect(() => {
    // document.addEventListener("globe", hideVideo, false);
    setTimeout(() => setReveal(true), 500);

    // return () => document.removeEventListener("globe", hideVideo, false);
  }, []);

  return (
    <IntroContainer>
      <a
        href="https://www.cssdesignawards.com/sites/iba-community-baking-worldwide/42182/"
        target="__blank"
      >
        <img id="css-award" alt="css-award" src={award} />
      </a>
      <DragAnimation
        hide={transitionToGlobe}
        className={transitionToGlobe ? "appear" : "appear active"}
        ref={lottieContainer}
      />
      <SpotlightBackground
        image={grid}
        active={true}
        className={reveal ? "appear active" : "appear"}
        videoClass={!transitionToGlobe ? "appear active" : "appear"}
        reveal={reveal}
      />
      <TitleCard>
        <div style={{ maxWidth: 210 }}>
          <ParticleEffectButton
            hidden={tutorialActive}
            color={Colors.yellow}
            direction="top"
          >
            <Button
              style={{ marginTop: 39 }}
              onClick={() => startTutorial()}
              className={
                reveal || tutorialActive
                  ? "show-up show-order-7 active"
                  : "show-up show-order-7"
              }
              size="small"
            >
              <img alt="arrow" src={arrow} />
              <p className="button mainBlue">Enter Now</p>
            </Button>
          </ParticleEffectButton>
        </div>
        <h1
          style={{ marginTop: 70 }}
          className={
            reveal ? "show-up active show-order-5" : "show-up show-order-5"
          }
        >
          Discover the love of baking around the world.
        </h1>
        {/* <p
          className={
            reveal ? "show-up show-order-6 active" : "show-order-6 show-up"
          }
        >
          iba is the worldwide meeting place for experts from the baking
          industry in unique dimension at the highest professional level.
        </p> */}
      </TitleCard>
      <SoundButton />
      <Logo />
      {/* <div style={{ bottom: '5%', left: 100, position: 'absolute' }}>
                <FrontCard headline="Click and drag to explore " subline="Navigate all different pins on the Globe." className={reveal ? "show-up active" : "show-up"} />
            </div> */}
    </IntroContainer>
  );
};

export default Intro;
