import React from "react";
import styled from "styled-components/macro";
import { FlexContainer } from "./common";

import msgPreview from "../assets/images/bakery_preview.jpg";
import portrait from "../assets/images/small_portrait.png";
import Image from "./Image";

const BakeryPreviewWrapper = styled.div`
  position: absolute;
  max-width: 273px;
  display: flex;
  flex-direction: column;
  left: 5%;
  top: 25%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s;
  pointer-events: none;
  z-index: 20;

  & * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  & #main-preview-image {
    position: relative;
    height: 357px;
    width: 273px;
    margin-top: 25px;
    margin-bottom: 17px;
    overflow: hidden;
  }

  & #main-preview-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    transition: opacity 0.4s;
  }

  @media (max-height: 800px) {
    top: 17%;
  }

  @media (max-height: 650px) {
    & #main-preview-image {
      height: 290px;
    }
  }
`;

const BakeryPreview = ({ active, bakery }) => {
  return (
    <BakeryPreviewWrapper active={active}>
      <p className="meta-1">Click pin to explore bakery!</p>
      <div id="main-preview-image">
        <Image
          simple
          alt="bakery-preview"
          src={bakery.gallery && bakery.gallery[0]}
          hide={!active}
        />
      </div>
      <FlexContainer justify="flex-start">
        {bakery && bakery.representative && (
          <img
            alt="bakery-portrait"
            src={bakery.portrait || portrait}
            style={{ marginRight: 15, borderRadius: 500 }}
          />
        )}
        {bakery.representative && (
          <FlexContainer direction="column" align="flex-start">
            <p>{bakery.representative || ""}</p>
            <p className="b2 yellow">{bakery.name + " " + bakery.role || ""}</p>
          </FlexContainer>
        )}
      </FlexContainer>
    </BakeryPreviewWrapper>
  );
};

export default BakeryPreview;
