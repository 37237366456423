import { MockMarkers } from "./MockMarkers";

export const parseHighlight = (text) => {
  const newText = text.split("\n").map((str, i) => (
    <span className={i === 1 ? "yellow" : ""} key={str}>
      {str}
    </span>
  ));
  return newText;
};

export const endpoint = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "https://iba-backend.herokuapp.com";
  } else {
    return "https://iba-backend.herokuapp.com";
  }
};

export const getMarkerType = (id) => {
  if (id.includes("message")) {
    return "MSG";
  } else if (id.includes("bakery")) {
    return "BAKE";
  } else {
    return "CLUST";
  }
};

export const populateFakeMarkers = () => {
  let marks = [];

  for (let i = 0; i < MockMarkers.length; i++) {
    let mark = MockMarkers[i];

    mark.coordinates = [mark.lat, mark.lon];
    mark.value = 20;
    mark.color = "#FFFFFF";

    delete mark.lat;
    delete mark.lon;

    marks.push(mark);
  }

  console.log("Done Parsing Markers");
  // setMarkers(marks)
};

export function detectOrientation(img) {
  let orientation;

  if (img.width > img.height) {
    orientation = "landscape";
  } else if (img.width < img.height) {
    orientation = "portrait";
  } else {
    orientation = "even";
  }

  return orientation;
}

export const userPlayAudio = () => {
  const audio = document.getElementById("main_audio");

  audio.volume = 0.1;

  if (audio.paused) audio.play();
};

export const playSound = (id, sound, startTime, volume) => {
  if (sound) {
    const audio = document.getElementById(id);

    console.log("Playing ", id);

    audio.volume = volume || 0.4;

    audio.currentTime = startTime || 0;

    if (audio.paused) audio.play();
  }
};

export const supportsWebGL = () => {
  try {
    return (
      !!window.WebGLRenderingContext &&
      !!document.createElement("canvas").getContext("experimental-webgl")
    );
  } catch (e) {
    return false;
  }
};

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str) && str.includes("linkedin");
}

export function checkEmailValidity(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const getCityFromGoogleData = (data) => {
  if (data && data.value.terms && data.value.terms.length > 1) {
    return data.value.terms[data.value.terms.length - 2].value;
  } else return data.value.terms[data.value.terms.length - 1].value;
};
