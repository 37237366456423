import styled from "styled-components/macro";
import { Colors } from "../../utils/Colors";

export const IntroContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.darkBlue};

  #main-intro-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  #css-award {
    position: fixed;
    left: 80px;
    top: 50px;
    z-index: 100;
    width: 100px;
  }

  @media (min-height: 950px) {
    & #globe-image {
      height: 100vh;
    }
  }
`;

export const TitleCard = styled.div`
  position: absolute;
  left: 100px;
  bottom: 60px;
  max-width: 1230px;
  display: flex;
  flex-direction: column;

  & h1 {
    font-weight: normal;
  }

  & img {
    max-width: 674px;
  }

  & p {
    max-width: 541px;
  }
`;

export const DragAnimation = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: ${({ hide }) =>
    hide ? "translate(-50%, -80%)" : "translate(-50%, -50%)"};
  pointer-events: none;
  width: 350px;
  z-index: 10;

  transition: transform 0.7s, opacity 0.7s !important;
`;
