import styled, { css } from "styled-components/macro";
import { Colors } from "../../utils/Colors";
import { animated } from "react-spring";

export const MiddleMessage = styled.p`
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 415px;
  text-align: center;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;

  transition: opacity 0.7s 0.7s;

  & img {
    height: 35px;
  }

  &.show {
    opacity: 1;
  }
`;

export const SpotlightNumber = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
    circle,
    transparent 18px,
    rgba(14, 14, 36, 0.9) 400px
  );

  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s;
  pointer-events: none;
`;

export const HoverLabel = styled(animated.div)`
  box-shadow: 0px 10px 40px #141418;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ type }) =>
    type === "BAKE" &&
    css`
      padding: 12px;
      min-width: 200px;
      height: 67px;
      background-color: ${Colors.darkBlue};
      border: 1px solid white;
      position: absolute;
      top: -77px;
      left: -210px;
      pointer-events: none;

      & h5 {
        line-height: 26px;
        color: ${Colors.yellow};
      }
    `}

  ${({ type }) =>
    type !== "BAKE" &&
    css`
      padding: 12px;
      min-width: 150px;
      min-height: 48px;
      background-color: rgba(255, 255, 255, 0.8);
      pointer-events: none;
      position: absolute;
      top: -58px;
      left: -160px;

      & p {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: ${Colors.mainBlue};
      }
    `}

  & * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
`;

export const GlobeContainer = styled.div`
  width: 100vw;
  cursor: grab;
  opacity: 1;
  transform: ${({ globeFocused, exploreMode }) =>
    "translateY(-10vh) " +
    (globeFocused
      ? "translateX(35%) scale(0.9)"
      : exploreMode
      ? "translateX(0) scale(1)"
      : "translateX(0) scale(0.8)")};

  transition: transform 2s ease, opacity 3s;
`;

export const GlobeCover = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: ${Colors.darkBlue};
  pointer-events: none;

  ${({ loaded }) =>
    !loaded &&
    css`
      opacity: 1;
    `}

  transition: opacity 5s .5s;
`;
