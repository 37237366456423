import React, { useState, useContext, useRef } from "react";
import ReactPlayer from "react-player/vimeo";
import Loader from "react-loader-spinner";

import { AudioContext } from "../../contexts/AudioContext";
import { Button } from "../../components/Buttons";
import FrontCard from "../../components/FrontCard";
import { FlexContainer } from "../../components/common";
import {
  BakeryProfileOverlay,
  Quote,
  SideDrawer,
  FullGlobe,
  BakerySplash,
  SplashBacgkround,
  BakeryVideoContainer,
} from "./BakeryProfile.styles";
import ReturnButton from "../../components/ReturnButton";

import search from "../../assets/icons/arrowright.svg";
import arrow from "../../assets/icons/rounded.svg";
import closeIcon from "../../assets/icons/close.svg";
import bg from "../../assets/video/ibaConnect2021_bgVid_ath_sE_1280x720.mp4";
import RevealSplitText from "../../components/RevealSplitText";
import { Colors } from "../../utils/Colors";

let notPlayingTimeout;

const BakeryProfile = ({ bakery, close, visible, goToShowMore }) => {
  const videoRef = useRef();
  const [, setAudio] = useContext(AudioContext);
  const [loaded, setLoaded] = useState(false);
  const [playTour, setPlayTour] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [fadePlayer, setFadePlayer] = useState(true);
  const [notPlayingError, setNotPlayingError] = useState(false);

  const imageLoaded = () => {
    setLoaded(true);
  };

  const onCloseProfile = () => {
    setLoaded(false);
    setTimeout(close, 900);
  };

  const onStartVideoPlayer = () => {
    console.log("Starting Video Player");
    setFadePlayer(false);
    videoRef.current.seekTo(20);
    setPlayTour(true);
    console.log("Fade Player ", fadePlayer, ". Play Tour ", playTour);
    notPlayingTimeout = setTimeout(() => {
      console.log(
        "After timeout, Fade Player ",
        fadePlayer,
        ". Play Tour ",
        playTour
      );
      if (playTour === false) {
        setPlayTour(true);
        console.log("Force Show the Player because playTour is ", playTour);
        setNotPlayingError(true);
        setShowPlayer(true);
        setAudio(false);
      }
    }, 2500);
  };

  const onCloseVideoPlayer = () => {
    setFadePlayer(true);
    setShowPlayer(false);
    setTimeout(() => {
      setPlayTour(false);
      setAudio(true);
    }, 1500);
  };

  const onVideoPlayed = () => {
    clearTimeout(notPlayingTimeout);
    console.log("Video has played successfully. Message from React-Player.");
    setShowPlayer(true);
    setAudio(false);
    setNotPlayingError(false);
  };

  return (
    <BakeryProfileOverlay
      className={
        loaded
          ? visible
            ? "loaded visible"
            : "loaded"
          : visible
          ? "visible"
          : ""
      }
      bg={bakery.background}
    >
      {bakery.backgroundVideo === "" ? (
        <img
          alt="placeholder"
          src={bakery.background}
          onLoad={() => imageLoaded()}
        />
      ) : (
        <video
          playsInline
          muted
          autoPlay
          loop
          onPlay={() => imageLoaded()}
          className={
            loaded ? "show-up show-order-6 active" : "show-up show-order-6"
          }
        >
          <source src={bakery.backgroundVideo || bg} type="video/mp4" />
        </video>
      )}
      {loaded && visible && (
        <BakeryVideoContainer
          className={showPlayer ? "ready" : fadePlayer ? "hide" : ""}
        >
          <img
            alt="close"
            src={closeIcon}
            style={{
              position: "absolute",
              top: "5%",
              left: "3%",
              cursor: "pointer",
              zIndex: 20,
            }}
            onClick={onCloseVideoPlayer}
          />
          <FrontCard
            headline={
              notPlayingError ? "Click anywhere to start" : "Drag to explore"
            }
            subline="Turn on subtitles by clicking CC on the bottom right"
            style={{
              position: "absolute",
              top: "5%",
              left: "50%",
              transform: "translateX(-50%)",
              cursor: "pointer",
              zIndex: 20,
            }}
          />
          <ReactPlayer
            ref={videoRef}
            className="video-player"
            url={bakery.virtualTour}
            width="100%"
            height="100%"
            controls={true}
            playing={playTour}
            onReady={() => console.log("Video Ready")}
            onStart={onVideoPlayed}
            onEnded={onCloseVideoPlayer}
          />
        </BakeryVideoContainer>
      )}
      <ReturnButton
        close={onCloseProfile}
        style={{ left: 90 }}
        className={showPlayer ? "hide return-button" : "return-button"}
      />
      <BakerySplash className={showPlayer ? "hide" : ""}>
        <SplashBacgkround />
        <p
          className={
            loaded
              ? "meta-2 yellow appear active show-order-12"
              : "meta-2 yellow appear show-order-12"
          }
        >
          Featured Bakery
        </p>
        <RevealSplitText wordDelay={0.2} wait={!loaded}>
          {bakery.headline}
        </RevealSplitText>
        <p
          className={
            loaded
              ? "appear active show-order-12 description"
              : "appear show-order-12 description"
          }
        >
          {bakery.mainDescription}
        </p>
        {bakery.virtualTour !== "" ? (
          <Button
            className={
              loaded ? "appear active show-order-12" : "appear show-order-12"
            }
            size="medium"
            onClick={onStartVideoPlayer}
          >
            <p className="button mainBlue">Play 360° Video</p>
            {!playTour ? (
              <img alt="arrow" src={arrow} />
            ) : (
              <Loader
                type="TailSpin"
                color={Colors.darkBlue}
                height={"25px"}
                width={"25px"}
              />
            )}
          </Button>
        ) : null}
      </BakerySplash>
      <SideDrawer className={showPlayer ? "slide" : !loaded ? "slide" : ""}>
        <FullGlobe
          alt="globe"
          src={bakery.fullGlobe}
          style={{
            height: bakery.name === "Sandholt Bakery" ? "30vh" : null,
            marginBottom: bakery.name === "Sandholt Bakery" ? "2vh" : null,
          }}
        />
        {bakery.representative && (
          <FlexContainer
            size="none"
            style={{ width: "100%", marginTop: "5vh" }}
            className="owner"
          >
            <img
              alt="owner"
              src={bakery.portrait}
              style={{ marginRight: 20, borderRadius: 500 }}
            />
            <FlexContainer direction="column" align="flex-start">
              <p>{bakery.representative}</p>
              <p className="b2 yellow">{bakery.role}</p>
            </FlexContainer>
          </FlexContainer>
        )}
        <Quote style={{ marginTop: "5vh" }}>{bakery.quote}</Quote>
        <FlexContainer
          justify="flex-start"
          size="none"
          style={{ width: "100%" }}
        >
          <Button
            style={{ marginTop: "5vh" }}
            size="medium"
            transparent
            onClick={goToShowMore}
          >
            <img alt="arrow" src={search} />
            <p className="button white">Learn More</p>
          </Button>
        </FlexContainer>
      </SideDrawer>
      {/* <CursorButton active={cursorActive && !onButton && !showPlayer} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px, ${y}px, 0)`) }} /> */}
    </BakeryProfileOverlay>
  );
};

export default BakeryProfile;
