import React, { useState, createContext, useRef, useEffect } from 'react';

import bg_music from "../assets/sounds/background_audio.mp3";
import globe_reveal from "../assets/sounds/globe_reveal.mp3";
import globe_zoom from "../assets/sounds/globe_zoom_in_overlays.mp3";
import particle_buttons from "../assets/sounds/particle_buttons.mp3";

// Create Context Object
export const AudioContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const AudioContextProvider = props => {
  const audioBG = useRef(null)
  const [audio, setAudio] = useState(true);

  useEffect(() => {
    var fadeAudio
    if (audioBG.current && !audioBG.current.paused) {
      if (!audio) {
        fadeAudio = setInterval(function () {
          console.log(audioBG.current.volume)
          if (audioBG.current.volume > 0.0) {
            audioBG.current.volume -= 0.1;
            console.log(audioBG.current.volume)
          }
  
          if (audioBG.current.volume === 0.0) {
              clearInterval(fadeAudio);
          }
        }, 200);
      } else if (audio && audioBG.current.volume === 0) {
        fadeAudio = setInterval(function () {
          if (audioBG.current.volume !== 0.1) {
              audioBG.current.volume += 0.1;
          }
  
          if (audioBG.current.volume >= 0.1) {
              clearInterval(fadeAudio);
          }
        }, 200);
      }
    }
  }, [audio])

  return (
    <AudioContext.Provider value={[audio, setAudio]}>
      <audio ref={audioBG} src={bg_music} loop id="main_audio"></audio>
      <audio src={globe_reveal} id="globe_reveal" preload="auto"></audio>
      <audio src={globe_zoom} id="globe_zoom" preload="auto"></audio>
      <audio src={particle_buttons} id="particle_buttons" preload="auto"></audio>
      {props.children}
    </AudioContext.Provider>
  );
};