import styled, { css } from "styled-components/macro";

const B1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 80px rgba(255, 209, 0, 0.1);
  border-radius: 56px;
  width: 210px;
  height: 48px;

  ${({ size }) =>
    size === "small" &&
    css`
      width: 210px;
    `}

  ${({ size }) =>
    size === "medium" &&
    css`
      width: 274px;
    `}

    ${({ size }) =>
    size === "large" &&
    css`
      width: 288px;
    `}

    ${({ transparent }) =>
    transparent &&
    css`
      background-color: transparent !important;
      border: 1px solid white;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
    
    & img:first-child, div:first-child {
    margin-right: 16px;
  }

  & img:last-child,
  div:last-child {
    margin-left: 16px;
  }
`;

export const Button = ({
  children,
  size,
  transparent,
  className,
  disabled,
  ...props
}) => {
  return (
    <B1
      {...props}
      size={size}
      transparent={transparent}
      className={"button yellow-bg mainBlue " + className}
      disabled={disabled}
    >
      {children}
    </B1>
  );
};
